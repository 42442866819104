// import React from "react";
// import {
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   ThemeProvider,
//   createTheme,
//   CardMedia,
//   CardActions,
//   CardActionArea,
//   Button,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import { fontFamily } from "@mui/system";

// // import { styled } from "@mui/system";
// // const ResourceCard = styled(Card)(({ theme }) => ({
// //   height: "100%",
// //   display: "flex",
// //   flexDirection: "column",
// //   justifyContent: "space-between",
// //   borderRadius: theme.spacing(2),
// //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
// //   transition: "transform 0.3s ease",
// //   "&:hover": {
// //     transform: "translateY(-5px)",
// //   },
// // }));

// function Resources() {
//   // Define custom theme for the resource section
//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: "#1F0954", // Your primary color
//       },
//     },
//   });

//   return (
//     <ThemeProvider theme={theme}>
//       <div className="text-center bg-[#F5F5F5]">
//         <div
//           className="container"
//           style={{
//             padding: "10",
//             minHeight: "88vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//           }}
//         >
//           <h1 className="font-bold text-3xl lg:text-5xl my-5 p-3">Resources</h1>
//           <Grid container spacing={4} className="justify-between">
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                   fontFamily: "Poppins",
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/finance1.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Finance
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link sx={{ fontFamily: "Poppins" }} to="/finance">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/education.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Education
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link to="/education">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Card
//                 sx={{
//                   transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
//                   "&:hover": {
//                     transform: "scale(1.05)", // Scale to 1.1 on hover
//                   },
//                 }}
//               >
//                 <CardActionArea>
//                   <CardMedia
//                     component="img"
//                     height="140"
//                     image="images/success_story.jpg"
//                     alt="green iguana"
//                   />
//                   <CardContent>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       gutterBottom
//                       variant="h5"
//                       component="div"
//                     >
//                       Success Stories
//                     </Typography>
//                     <Typography
//                       sx={{ fontFamily: "Poppins" }}
//                       variant="body2"
//                       color="text.secondary"
//                     >
//                       Lizards are a widespread group of squamate reptiles, with
//                       over 6,000 species, ranging across all continents except
//                       Antarctica
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//                 <CardActions className="justify-center">
//                   <div>
//                     <Link to="/success_stories">
//                       <div class="">
//                         <a className="abc">
//                           <span>Explore</span>
//                         </a>
//                       </div>
//                     </Link>
//                   </div>
//                 </CardActions>
//               </Card>
//             </Grid>

//             {/* Add more Grid items for additional resources */}
//           </Grid>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default Resources;

import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion

// Define card animation variants
const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function Resources() {
  // Define custom theme for the resource section
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  // Array of resources with titles, routes, and image paths
  const resources = [
    { title: "Finance", route: "finance", image: "images/finance1.jpg" },
    { title: "Education", route: "education", image: "images/education.jpg" },
    {
      title: "Success Story",
      route: "success_stories",
      image: "images/success_story.jpg",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="text-center bg-[#F5F5F5]">
        <div
          style={{
            padding: "10px",
            margin: "20px",
            minHeight: "88vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="font-bold text-3xl lg:text-5xl mb-4 p-3">Resources</h1>
          <Grid container spacing={4} justifyContent="center">
            {resources.map((resource, index) => (
              <Grid item xs={12} sm={6} md={4} key={resource.route}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                  transition={{ duration: 0.5, delay: index * 0.1 }} // Delay for staggered entrance
                >
                  <Card
                    sx={{
                      transition: "transform 0.4s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      fontFamily: "Poppins",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={resource.image} // Dynamically set image
                        alt={resource.title}
                      />
                      <CardContent>
                        <Typography
                          sx={{ fontFamily: "Poppins" }}
                          gutterBottom
                          variant="h5"
                          component="div"
                        >
                          {resource.title}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: "Poppins" }}
                          variant="body2"
                          color="text.secondary"
                        >
                          This section includes resources related to{" "}
                          {resource.title.toLowerCase()}.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions className="justify-center">
                      <Link to={`/${resource.route}`}>
                        <div className="abc">
                          <span>Explore</span>
                        </div>
                      </Link>
                    </CardActions>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Resources;
